:root {
  --text-copyright-bg: #04132a;
}

h4 {
  font-weight: 500;
}

.br-menu {
  .menu-body .drop-menu.active {
    > .menu-item {
      background-image: none;
      background-color: #0c326f !important;
      color: #fff !important;
    }

    a.menu-item:not(:disabled):not(:disabled):hover {
      background: none;
      color: var(--menu-item-color);
      background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)));
    }
  }

  a.menu-item:not(:disabled):not(:disabled):hover {
    background-image: none;
    background-color: #0c326f;
    color: #fff;
  }
}

.br-scrim.foco {
  z-index: 2000;
}

.br-accordion {
  width: 100%;

  .header {
    align-items: center;
  }
}

.br-header.compact .header-bottom {
  margin-top: 0;
}

.br-breadcrumb {
  display: block;

  .content {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.br-tab {
  .tab-item {
    width: 100%;

    button {
      width: 100%;
    }
  }

  .tab-nav ul {
    justify-content: space-between;
  }
}

.br-upload {
  .br-button.upload-button {
    max-width: 100%;
  }
  .upload-list {
    max-width: 100%;
  }
}

.br-cookiebar {
  .br-modal-body .br-list .header {
    display: none;
  }

  .group-info .br-button {
    display: none;
  }

  .cookies-checked {
    display: none;
  }

  .group-size {
    display: none !important;
  }
}

p {
  text-align: justify;
}

.text-copyright {
  background-color: var(--text-copyright-bg);

  .text {
    color: #fff;
  }
}

a:not(:disabled):hover {
  background-image: none;
}

.feedback {
  border-radius: 0.5rem;
}

.medicao {
  color: #fff;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 0.5rem;
}

#medidor {
  max-height: 500px;
}

#btn-scroll-to-top {
  position: fixed;
  display: block;
  height: 50px;
  width: 50px;
  bottom: 1rem;
  right: 1rem;
  font-size: 1rem;
  z-index: 99;
  border: 1px solid #fff;
  outline: none;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  background-color: #004b99;
  /* also add a translate effect */
  transform: translateY(100px);
  /* and a transition */
  transition: all 0.5s ease;

  &:hover {
    background-color: #dbe8fb;
  }
}

.show-btn {
  opacity: 1;
  transform: translateY(0) !important;
}

// VLibras
div[vw] {
  z-index: 4 !important;
  margin-top: -40vh !important;
}

.reset-cookies {
  display: flex;
  margin-bottom: 2rem;

  i {
    width: inherit;
  }

  a {
    color: #fff;
  }

  span {
    font-size: var(--font-size-scale-up-01);
    font-weight: 500;
    margin-left: 0.5rem;
  }
}

.social-networks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
  color: #fff;

  .title {
    font-weight: 700;
    font-size: var(--font-size-scale-up-01);
    line-height: 2.2rem;
    margin-bottom: 0.5rem;
  }

  .logo-list {
    display: flex;

    .logo-item {
      font-size: 1.5rem;
      margin-right: 1rem;

      a {
        color: #fff;
      }
    }
  }
}

.header-actions .header-functions .br-list {
  left: -12em !important;
}
